// Mobile App Mockups *
import mockup1 from "assets/mockups/mockup_1.png";
import mockup2 from "assets/mockups/mockup_2.png";

const MockupsList = {
  mockup1,
  mockup2
};

export default MockupsList;
