import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography, Button, Box, Container } from "@material-ui/core";
import Link from "@material-ui/core/Link";
const HolistikallyInput = withStyles(theme => ({
  input: {
    borderRadius: 30,
    position: "relative",
    backgroundColor: "#eeece7",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "320px",
    marginBottom: "20px",
    boxShadow: "inset 0 -2px 4px 0 #ffffff, inset 0 2px 4px 0 #4a4a4a66",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${fade(theme.palette.secondary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    },
    "&::placeholder": {
      color: theme.palette.secondary.main,
      fontWeight: "bold"
    }
  }
}))(InputBase);

const SignupForm = props => {
  const [checked, setChecked] = useState(false);

  const toggleChecked = () => {
    setChecked(prev => !prev);
  };
  const { backgroundColor, backgroundImage } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        padding={10}
      >
        <Container>
          <Grid container alignItems="flex-start" display="row">
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              item
            >
              <Box display="flex" mt={0} justifyContent="center">
                <Typography variant="h4">Welcome!</Typography>
              </Box>
              <Box display="flex" mt={0} justifyContent="center">
                <Typography variant="body2" align="center">
                  Interested in learning more about holistikally? <br />
                  Sign up to receive updates!
                </Typography>
              </Box>
            </Grid>

            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              item
            >
              <Box mt={4}>
                <form>
                  <FormGroup>
                    <Box mt={1} display="flex" justifyContent="center">
                      <FormControl>
                        <HolistikallyInput placeholder="Username" required />
                      </FormControl>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="center">
                      <FormControl>
                        <HolistikallyInput
                          placeholder="Your email"
                          required
                          type="email"
                        />
                      </FormControl>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="center">
                      <FormControl>
                        <HolistikallyInput
                          placeholder="Type Password"
                          type="password"
                          required
                        />
                      </FormControl>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="center">
                      <FormControl>
                        <HolistikallyInput
                          placeholder="Type again"
                          type="password"
                          required
                        />
                      </FormControl>
                    </Box>
                    <Box mt={1} ml={1} maxWidth={320}>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={checked}
                            onChange={toggleChecked}
                          />
                        }
                        label={
                          <Box ml={2}>
                            <Typography>
                              I am 18 years or older and agree to{" "}
                              <a
                                href="#"
                                style={{
                                  textDecoration: "none",
                                  color: "#df8166"
                                }}
                              >
                                Terms & Conditions
                              </a>
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                    <Box mt={1}>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!checked}
                        fullWidth
                        size="large"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </FormGroup>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SignupForm;
