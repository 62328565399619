// Section ClipArts *
import outOfTheBox from "assets/illustrations/outofthebox.svg";
import chilling from "assets/illustrations/chilling.svg";
import happy from "assets/illustrations/happy.svg";
import hi from "assets/illustrations/hi.svg";
import plantCare from "assets/illustrations/plant-care.svg";
import reading from "assets/illustrations/reading.svg";
import sitting from "assets/illustrations/sitting.svg";
const IllustrationsList = {
  outOfTheBox,
  chilling,
  happy,
  hi,
  plantCare,
  reading,
  sitting
};

export default IllustrationsList;
