import React from "react";
import MainNavBar from "Components/UI-Elements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import FooterWidget from "Components/UI-Elements/FooterWidget";
import Footer from "Components/UI-Elements/Footer";

import { ThemeProvider } from "@material-ui/core/styles";

const About = props => {
  return (
    <ThemeProvider theme={ThemeConfig}>
      <MainNavBar backgroundColor="transparent" />
      <FooterWidget backgroundColor="#ffffff" />
      <Footer backgroundColor="#ffffff" />
    </ThemeProvider>
  );
};

export default About;
