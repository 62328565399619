import React from "react";

// Banner Section *
import Testimonials from "Components/VisualComponents/Testimonials";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";

const testimonialTexts = [
  {
    content:
      "Before holistikally, I was not able to find a therapist who looked like me and shared my faith. holistikally matched me with a black, Christian woman therapist, Carmen,  who understood nuances about my background immediately. I felt comfortable and didn't need to explain myself. I do enough of that at work.  holistikally gave me a safe space to be myself and get right to the heart of my issues.",
    user: "Candace, Black-Christian-Straight, 31"
  },
  {
    content:
      "As a gay male executive, I'm usually traveling for work constantly.  I needed a flexible option to text or video chat with a thearpist. holistikally has been a gift; I've found my therapist who is a gay man as well. It's so easy to communicate as and when we need to through the holistikally app",
    user: "Ahmed, Latin-Agnostic-Gay, 43"
  }
];

const HomeTestimonials = props => {
  return (
    <Testimonials
      sectionTitle="What our users say"
      sectionTitleVariant="h4"
      testimonialsContent={testimonialTexts}
      testimonial
      backgroundColor="#ffffff"
    />
  );
};

export default HomeTestimonials;
