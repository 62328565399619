import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AppstoreList from "assets/AppstoreList";
import Hidden from "@material-ui/core/Hidden";

import { Typography, Button, Box, Container } from "@material-ui/core";
import Link from "@material-ui/core/Link";
const MockupSection = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    sectionTitleColor,
    sectionColumns,
    sectionImage,
    backgroundColor,
    backgroundImage
  } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        padding={10}
      >
        <Container>
          <Grid
            container
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item lg={6} xs={12} sm={12} md={8}>
              <Box>
                <img src={sectionImage} width="403px" alt="section image" />
              </Box>
            </Grid>
            <Grid item lg={6} xs={12} sm={12} md={8}>
              <Box mt={"10%"}>
                <Box>
                  <Typography
                    variant={sectionTitleVariant}
                    color={sectionTitleColor}
                  >
                    {sectionTitle}
                  </Typography>
                </Box>
                {/* Moto With Divider */}
                {sectionColumns.map((column, index) => {
                  return (
                    <Box mb={2} key={index} maxWidth={440}>
                      <Box mt={4} mb={4}>
                        <Typography
                          variant="h5"
                          color="secondary"
                          style={{ fontWeight: "bolder" }}
                        >
                          {column.title}
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        <Typography variant="body2">
                          {column.content}
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        <Divider variant="fullWidth" />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default MockupSection;
