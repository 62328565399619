import React from "react";

// Banner Section *
import BannerWithTitles from "Components/VisualComponents/BannerWithTitles";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";

const sectionColumns = [
  {
    title: "More clients, more money, less time",
    content:
      "with options to text, call or video chat, you can help more clients and earn more money over short periods of time!"
  },
  {
    title: "Flexible hours and location",
    content: "you can work with patients from the comfort of your home !"
  }
];

const SectionWithTitles = props => {
  return (
    <BannerWithTitles
      sectionTitle="Why therapists love us"
      sectionTitleVariant="h3"
      sectionTitleColor="primary"
      sectionColumns={sectionColumns}
      sectionImage={Illustrations.reading}
      backgroundColor="transparent"
    />
  );
};

export default SectionWithTitles;
