import React from "react";

// Banner Section *
import MockupSection from "Components/VisualComponents/MockupSection";

// Mockups Design *
import MockupsList from "assets/MockupsList";
const motosContent = [
  "We make therapy flexible and convenient",
  "Affordability is a priority",
  "Your privacy is paramount"
];
const MobileBanner = props => {
  return (
    <MockupSection
      sectionTitle="Why people love us"
      sectionTitleVariant="h3"
      sectionTitleColor="primary"
      sectionSubtitle="Identity Sensitive Therapy works"
      sectionSubtitleColor="secondary"
      sectionContent="holistikally uniquely matches you up with therapists who can identify with people with 
                      your identity blueprint and effectively treat them. our algorithm takes in account your
                      faith background, culture, race and sexuality, among other identity markers.
                      We find the best therapist for you!"
      sectionMotos={motosContent}
      sectionImage={MockupsList.mockup2}
      sectionImageAlign="left"
      buttonVariant="contained"
      buttonColor="primary"
      buttonLabel="Download App"
      buttonHref="download"
      backgroundColor="transparent"
      showAppStores={true}
    />
  );
};

export default MobileBanner;
