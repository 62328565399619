import React from "react";

// Banner Section *
import CardsSection from "../../Components/VisualComponents/CardsSection";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";
const CardsContent = [
  {
    title: "Sign Up",
    content: "Let us know a little bit about you, and what you’re looking for",
    image: Illustrations.plantCare
  },
  {
    title: "Match with a counselor",
    content:
      "Based on your identity blueprint ™ which we generate based on your profile information, our algorithm matches you with a licensed therapist who is uniquely positioned to support you in your mental health journey",
    image: Illustrations.chilling
  },
  {
    title: "Begin your journey",
    content:
      "Text, Call and Video Chat with your counselor from anywhere in the world",
    image: Illustrations.sitting
  }
];

const CardsGrid = props => {
  return (
    <CardsSection
      sectionTitle="How it works"
      sectionTitleVariant="h4"
      sectionContent="Sign up and get matched with a lisensed counselor today!"
      sectionSubtitle="It’s easy and simple!"
      sectionSubtitleVariant="h5"
      sectionContentTitle1="Sign Up"
      sectionCardsContent={CardsContent}
      backgroundColor="#ffffff"
      showTerms={true}
    />
  );
};

export default CardsGrid;
