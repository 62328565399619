import { createMuiTheme } from "@material-ui/core/styles";

const ThemeConfig = createMuiTheme({
  // Color palette
  palette: {
    primary: {
      main: "#97a49a"
    },
    secondary: {
      main: "#df8166"
    },
    // Font Colors *
    text: {
      default: "#4a4a4a",
      primary: "#1e272e",
      secondary: "#df8166"
    }
  },
  status: {
    danger: "orange"
  },

  // Font *
  typography: {
    h3: {
      color: "#df8166",
      fontFamily: "Work Sans",
      fontWeight: "bold"
    },
    subtitle1: {
      color: "#1e272e",
      fontSize: "24px"
    },
    fontFamily: "Work Sans"
  },
  overrides: {
    // Buttons ⚛️
    MuiButton: {
      root: {
        borderRadius: "50px",
        textTransform: "capitalize",
        paddingLeft: 60,
        paddingRight: 60,
        margin: 5,
        color: "#f8f7f3"
      },
      contained: {
        paddingLeft: 60,
        paddingRight: 60,
        margin: 5
      },
      outlined: {
        paddingLeft: 60,
        paddingRight: 60,
        margin: 5
      },
      containedPrimary: {
        color: "#f8f7f3"
      },
      containedSecondary: {
        color: "#ffffff"
      },
      outlinedPrimary: {
        color: "#97a49a"
      }
    },
    // Typography ⚛️
    MuiTypography: {
      root: {
        fontFamily: "Work Sans"
      },
      subtitle2: {
        color: "#4a4a4a"
      },
      colorPrimary: {
        color: "#4a4a4a"
      },
      h6: {
        fontWeight: "bold"
      },
      h3: {
        fontWeight: "lighter"
      },
      body2: {
        fontSize: "18px"
      }
    },
    MuiLink: {
      root: {
        color: "#df8166"
      }
    },
    // AppBar ⚛️
    MuiAppBar: {
      root: {
        boxShadow: "none",
        backgroundColor: "transparent"
      }
    }
  }
});

export default ThemeConfig;
