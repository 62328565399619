import React from "react";
import Typography from "@material-ui/core/Typography";
import AppstoreList from "assets/AppstoreList";
import { Container, Box, Grid } from "@material-ui/core";

export default function FooterWidget(props) {
  const { backgroundColor } = props;

  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
        bottom: 0
      }}
      pb={4}
    >
      <Container>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <Box pt={7}>
              <Typography
                variant="h6"
                style={{ color: "#97a49a", fontSize: "24px" }}
              >
                holistikally
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={12} md={12}>
            <Box maxWidth={350} mt={1} pb={3}>
              <Typography
                variant="body2"
                style={{ color: "#2b292d99", lineHeight: "1.44" }}
              >
                A health care app that gives its users the ability to seek help
                for stigmatized conditions.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box display="inline" mr={2}>
              <img
                src={AppstoreList.appleStore}
                width={"140px"}
                alt="App Store"
              />
            </Box>
            <Box display="inline" mr={2}>
              <img
                src={AppstoreList.googleStore}
                width={"140px"}
                alt="Google Store"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
