import React from "react";
import MainNavBar from "Components/UI-Elements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import FooterWidget from "Components/UI-Elements/FooterWidget";
import Footer from "Components/UI-Elements/Footer";
import SectionWithImage from "./SectionWithImage";
import SignUpForm from "./SignUpForm";

import { ThemeProvider } from "@material-ui/core/styles";

const UserSignup = props => {
  return (
    <ThemeProvider theme={ThemeConfig}>
      <MainNavBar backgroundColor="transparent" />
      <SectionWithImage />
      <SignUpForm backgroundColor="#ffffff" />
      <FooterWidget backgroundColor="#ffffff" />
      <Footer backgroundColor="#ffffff" />
    </ThemeProvider>
  );
};

export default UserSignup;
