import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import ScrollToTopRoute from "./ScrollToTopRoute";
import "./App.css";

/*---Pages ---*/

import Home from "./Pages/Home";
import About from "./Pages/About";
import UserSignup from "Pages/Signup/User";
import TherapistSignup from "Pages/Signup/Therapist";

function App() {
  return (
    <Router>
      <Switch>
        <ScrollToTopRoute
          exact={true}
          path={"/"}
          component={Home}
          pageTitle="Home"
        />
        <ScrollToTopRoute
          exact={true}
          path={"/about"}
          component={About}
          pageTitle={"About"}
        />
        <ScrollToTopRoute
          exact={true}
          path={"/user/signup"}
          component={UserSignup}
          pageTitle="User Signup"
        />
        <ScrollToTopRoute
          exact={true}
          path={"/therapist/signup"}
          component={TherapistSignup}
          pageTitle="Therapist Signup"
        />
      </Switch>
    </Router>
  );
}

export default App;
