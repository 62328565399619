import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AppstoreList from "assets/AppstoreList";
import Hidden from "@material-ui/core/Hidden";

import { Typography, Button, Box, Container } from "@material-ui/core";
import Link from "@material-ui/core/Link";
const MockupSection = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    sectionTitleColor,
    sectionSubtitle,
    sectionSubtitleColor,
    sectionContent,
    sectionMotos,
    sectionImage,
    sectionImageAlign,
    buttonLabel,
    buttonVariant,
    buttonHref,
    buttonColor,
    backgroundColor,
    backgroundImage,
    showAppStores
  } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        padding={10}
      >
        <Container>
          <Grid
            container
            direction={sectionImageAlign === "right" ? "row" : "row-reverse"}
            justify="center"
            alignContent="flex-start"
            alignItems="stretch"
          >
            <Grid item lg={5} xs={12} sm={12} md={8}>
              <Box mt={"10%"}>
                <Box>
                  <Typography
                    variant={sectionTitleVariant}
                    color={sectionTitleColor}
                  >
                    {sectionTitle}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography color={sectionSubtitleColor} variant="h6">
                    {sectionSubtitle}
                  </Typography>
                </Box>
                <Box mt={2} minWidth={350} maxWidth={480}>
                  <Typography variant="body2">{sectionContent}</Typography>
                </Box>
                <Box mt={5}>
                  <Divider
                    variant="fullWidth"
                    style={{ backgroundColor: "#df8166", height: "3px" }}
                  />
                </Box>

                {/* Moto With Divider */}
                {sectionMotos.map((moto, index) => {
                  return (
                    <Box key={index}>
                      <Box mt={4} mb={4}>
                        <Typography
                          style={{
                            fontSize: "22px",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            color: "#9b9b9b"
                          }}
                        >
                          {moto}
                        </Typography>
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}

                {/* Button */}
                <Box mt={2}>
                  <Button
                    variant={buttonVariant}
                    color={buttonColor}
                    href={buttonHref}
                  >
                    {buttonLabel}
                  </Button>
                </Box>
              </Box>
              {/* App Stores */}
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignContent="flex-start"
                alignItems="baseline"
              >
                <Box maxWidth={"135px"} mt={3} mr={1}>
                  <Link>
                    <img
                      src={AppstoreList.appleStore}
                      width="100%"
                      alt="Apple Store"
                    />
                  </Link>
                </Box>
                <Box maxWidth={"135px"} mt={3}>
                  <Link>
                    <img
                      src={AppstoreList.googleStore}
                      width="100%"
                      alt="Google Play"
                    />
                  </Link>
                </Box>
              </Grid>
            </Grid>

            {/* Section Image */}

            <Grid item lg={7} xs={12} md={4}>
              <Hidden xsDown smDown>
                <Box>
                  <img
                    src={sectionImage}
                    width="100%"
                    alt="Section's Clipart"
                  />
                </Box>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default MockupSection;
