import React from "react";
import MainNavBar from "Components/UI-Elements/MainNavBar";
import ThemeConfig from "Theme/ThemeConfig";
import { ThemeProvider } from "@material-ui/core/styles";
import HomeBanner from "Pages/Home/HomeBanner";
import MobileBanner from "Pages/Home/MobileBanner";
import SignupBanner from "Pages/Home/SignupBanner";
import CouncelorBanner from "Pages/Home/CouncelorBanner";
import CardsGrid from "Pages/Home/CardsGrid";
import HomeTestimonials from "./HomeTestimonials";
import FooterWidget from "Components/UI-Elements/FooterWidget";
import Footer from "Components/UI-Elements/Footer";

const Home = props => {
  return (
    <ThemeProvider theme={ThemeConfig}>
      <MainNavBar backgroundColor="white" />
      <HomeBanner />
      <SignupBanner />
      <CardsGrid />
      <MobileBanner />
      <HomeTestimonials />
      <CouncelorBanner />
      <FooterWidget backgroundColor="#ffffff" />
      <Footer backgroundColor="#ffffff" />
    </ThemeProvider>
  );
};

export default Home;
