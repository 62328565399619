import React from "react";
import Grid from "@material-ui/core/Grid";
import Carousel from "react-material-ui-carousel";

import { Typography, Button, Box, Container } from "@material-ui/core";

const Testimonials = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    testimonialsContent,
    backgroundImage,
    backgroundColor
  } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        padding={10}
        minHeight={400}
      >
        <Container>
          <Grid container alignItems="flex-start" display="row">
            <Grid
              item
              cs={4}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Typography
                variant={sectionTitleVariant}
                color="secondary"
                align="center"
              >
                {sectionTitle}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={7}>
            <Grid container alignItems="flex-start" display="row">
              <Grid
                item
                cs={4}
                container
                direction="column"
                alignItems="center"
                justify="center"
                className="testimonialDiv"
              >
                <Box maxWidth={400}>
                  <Carousel animation="fade" interval={4000}>
                    {testimonialsContent.map((testimonial, index) => {
                      return (
                        <Box key={index} minHeight={350}>
                          <Box>
                            <Typography align="center">
                              {testimonial.content}
                            </Typography>
                          </Box>
                          <Box mt={2}>
                            <Typography
                              align="center"
                              style={{ fontWeight: "bolder" }}
                            >
                              {testimonial.user}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Testimonials;
