import React from "react";

// Banner Section *
import BannerSection from "../../Components/VisualComponents/BannerSection";

import Illustrations from "assets/IllustrationsList";

const HomeBanner = props => {
  return (
    <BannerSection
      sectionTitle="Where your identity matters"
      sectionTitleVariant="h3"
      sectionContent="A health care app that gives its users the ability to
          seek help for stigmatized conditions."
      buttonVariant="contained"
      buttonColor="primary"
      buttonLabel="Learn More"
      buttonHref="about"
      sectionImage={Illustrations.outOfTheBox}
      sectionImageAlign="right"
      backgroundColor="white"
      showTerms={false}
    />
  );
};

export default HomeBanner;
