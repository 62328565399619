import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Container, Box } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    color: "#97a49a"
  },
  buttonAction: {
    boxShadow: "0 2px 4px 0 rgba(74, 74, 74, 0.4)",
    backgroundColor: "#97a49a",
    color: "#ffffff"
  }
}));

export default function MainNavBar(props) {
  const { backgroundColor } = props;

  const classes = useStyles();

  return (
    <Box style={{ backgroundColor: backgroundColor }}>
      <Container>
        <AppBar color={"transparent"} position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <a href="/">holistikally</a>
            </Typography>
            <Hidden xsDown smDown>
              <Button color="primary" href="/about">
                About Us
              </Button>
              <Button
                color="primary"
                variant="contained"
                href="/user/signup"
                style={{ color: "#ffffff" }}
              >
                Match with a Therapist
              </Button>
              <Button
                color="secondary"
                variant="contained"
                href="/therapist/signup"
                style={{ color: "#ffffff" }}
                size="medium"
              >
                Join as a Therapist
              </Button>
            </Hidden>
          </Toolbar>
        </AppBar>
      </Container>
    </Box>
  );
}
