import React from "react";

// Banner Section *
import BannerSection from "../../Components/VisualComponents/BannerSection";

import Illustrations from "assets/IllustrationsList";
import Backgrounds from "assets/BackgroundList";

const SignupBanner = props => {
  return (
    <BannerSection
      sectionTitle="Start your journey"
      sectionTitleVariant="h4"
      sectionContent="Sign up and get matched with a lisensed counselor today!"
      buttonVariant="contained"
      buttonColor="primary"
      buttonLabel="Sign Up"
      buttonHref="user/signup"
      sectionImage={Illustrations.hi}
      sectionImageAlign="left"
      backgroundImage={Backgrounds.light}
      showTerms={true}
    />
  );
};

export default SignupBanner;
