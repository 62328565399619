import React from "react";
import Grid from "@material-ui/core/Grid";

import { Typography, Button, Box, Container } from "@material-ui/core";
import Link from "@material-ui/core/Link";
const BannerSection = props => {
  const { sectionImage, backgroundColor, backgroundImage } = props;
  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        padding={10}
      >
        <Container>
          <Grid container alignItems="flex-start" display="row">
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              item
            >
              <Box display="flex" mt={0} justifyContent="center">
                <img
                  src={sectionImage}
                  width="403px"
                  height="301px"
                  alt="Section's Clipart"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default BannerSection;
