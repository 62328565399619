import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Facebook, Twitter, LinkedIn } from "@material-ui/icons";

import Button from "@material-ui/core/Button";
import { Container, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 2
  },
  buttonAction: {
    boxShadow: "0 2px 4px 0 rgba(74, 74, 74, 0.4)",
    backgroundColor: "#97a49a",
    color: "#ffffff"
  }
}));

export default function Footer(props) {
  const { backgroundColor } = props;

  const classes = useStyles();

  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
        borderTop: "solid 1px",
        borderColor: "#2b292d36",
        bottom: 0
      }}
    >
      <Container>
        <AppBar color={"transparent"} position="static">
          <Toolbar>
            <Box className={classes.title}>
              <Typography
                display="inline"
                variant="body1"
                style={{ marginRight: "15px" }}
              >
                Terms of Use
              </Typography>
              <Typography
                display="inline"
                variant="body1"
                style={{ marginRight: "15px" }}
              >
                Privacy Policy
              </Typography>
            </Box>
            <Box mr={2}>
              <Twitter color="primary" />
            </Box>
            <Box mr={2}>
              <Facebook color="primary" />
            </Box>
            <Box mr={2}>
              <LinkedIn color="primary" />
            </Box>
          </Toolbar>
        </AppBar>
      </Container>
    </Box>
  );
}
