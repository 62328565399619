import React from "react";

import Grid from "@material-ui/core/Grid";

import { Typography, Box, Container } from "@material-ui/core";

const CardsSection = props => {
  const {
    sectionTitle,
    sectionTitleVariant,
    sectionSubtitle,
    sectionSubtitleVariant,
    sectionCardsContent,
    backgroundColor,
    backgroundImage
  } = props;

  return (
    <>
      <Box
        style={{
          background: backgroundColor
            ? backgroundColor
            : `url(${backgroundImage}) no-repeat center center fixed`,
          backgroundSize: "cover"
        }}
        padding={10}
      >
        <Container>
          <Grid container alignItems="flex-start" display="row">
            <Grid
              item
              cs={4}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Typography
                color="secondary"
                variant={sectionTitleVariant}
                align="center"
                style={{ fontWeight: "bolder" }}
              >
                {sectionTitle}
              </Typography>
            </Grid>
            <Grid
              item
              cs={4}
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Typography variant={sectionSubtitleVariant} align="center">
                {sectionSubtitle}
              </Typography>
            </Grid>
          </Grid>

          {/* Cards */}
          <Box mt={8}>
            <Grid container alignItems="flex-start" display="row">
              {sectionCardsContent.map((card, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    lg={4}
                    item
                  >
                    <Box>
                      <img
                        src={card.image}
                        width="300px"
                        height="224px"
                        alt="Section thumbnail"
                      />
                    </Box>
                    <Box mt={2}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        align="center"
                        style={{ fontWeight: "bolder" }}
                      >
                        {card.title}
                      </Typography>
                    </Box>
                    <Box mt={2} mb={5} maxWidth={380}>
                      <Typography variant="body2" gutterBottom align="center">
                        {card.content}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CardsSection;
